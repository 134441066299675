import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Alert, FloatingLabel } from "react-bootstrap";

import TokenContext from "../context/TokenContext";

function Restorepassword() {
    const { codigoRestore, usuarioProvider } = useContext(TokenContext);

    const [codigo, setCodigo] = useState("");
    const [AlertMsm, setAlertMsm] = useState();
    const [TypeAlert, setTypeAlert] = useState("success");
    const [validateCode, setvalidateCode] = useState(false);

    const handleCodigo = (event) => {
      const input = event.target.value;
      setAlertMsm(false)
      
      if (/^\d*$/.test(input) && input.length <= 8) {
        setCodigo(input);
      }
    }

    const handleValidarCodigo = async () => {
        setAlertMsm(false)
        
        if (codigo.length < 8) {
          setAlertMsm("El codigo debe ser De 8 digitos")
          setTypeAlert("danger")
          return;
        }

        if(codigo==codigoRestore)
        {
          setvalidateCode(false);
        } else {
          setAlertMsm("Codigo Erroneo")
          setTypeAlert("danger")
        }
    }

    return (
      <div className="outer admin-page">
        <div >

        {validateCode ? (
            <>
              <div className="contain-restore-text1" style={{textAlign: 'center'}}>
                <label 
                  style={{
                  fontSize: '30px'
                  }}
                >
                  Ingresa el Codigo de 8 digitos que te Enviamos a tu correo de Registro.
                </label>
              </div>

              <div className="contain-restore-input" style={{marginBottom: '15px'}}>
                <Form.Control 
                  type="text" 
                  style={{textAlign:'center', fontSize: '30px'}}
                  value={codigo}
                  onChange={handleCodigo}
                />
              </div>

              <div className="contain-restore-input">
                <Button
                    variant="dark"
                    style={{
                        width: '100%',
                        marginBottom: '15px',
                    }}
                    onClick={() => {handleValidarCodigo()}}
                >
                    <b>INGRESAR</b>
                </Button>
                  
                {AlertMsm && (
                <Alert
                  variant={TypeAlert}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginTop: '15px',
                    fontSize: '1rem'
                  }}
                >
                  {AlertMsm}
                </Alert>
                )}

              </div>
            </>
        ) : ( 
            <>
            <div className="inner">
              <div style={{marginBottom: '25px'}}>
                <Form.Label htmlFor=""><b>Contraseña</b></Form.Label>
                <Form.Control
                  type="password"
                />
              </div>
              
              <div style={{marginBottom: '15px'}}>
                <Form.Label htmlFor="inputPassword5"><b>Verifica Contraseña</b></Form.Label>
                <Form.Control
                    type="password"
                    
                />
              </div>

              <div style={{textAlign: 'center'}}>
                <Button variant="primary">
                    <b>Guardar</b>
                </Button>
              </div>
            </div>
            </>
        )}
           
        </div>
      </div>
    );
}

export default Restorepassword;