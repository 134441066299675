import React from "react";

function Reporte() {
    return (
        <>
          <div>
            Reporte
          </div>
        </>
    );
}

export default Reporte;
