import { BrowserRouter, Route, useNavigate, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import AdminLayout from './layouts/Admin';
import Login from './layouts/Login';
import Restorepassword from './layouts/RestorePassword';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/styles1.css';

import { TokenProvider } from './context/TokenContext.js';

const root = ReactDOM.createRoot(document.getElementById("root"));

const RedirectToDashboard = () => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/admin/inicio");
  }, [navigate]);

  return null;
};

root.render(
  <TokenProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout/>} />
        <Route path="/" element={<RedirectToDashboard />} />
        <Route path="/login" element={<Login />} /> 
        <Route path="/config/restorepassword" element={<Restorepassword />} />
      </Routes>
    </BrowserRouter>
  </TokenProvider>
);
