import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Alert } from "react-bootstrap";
import Cookies from 'js-cookie';

import TokenContext from "../context/TokenContext";

function Login() {

  const { setToken, setCodigoRestore } = useContext(TokenContext);

  const [username, setUsername] = useState("");
  const [usernameClass, setClassUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordClass, setClassPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [msmError, setMsmError] = useState(false);
  const [msmOk, setMsmOk] = useState(false);

  const changeUsername = (event) => {
    setClassUsername(false)
    setUsername(event.target.value);
    setMsmError(false)
  }

  const changePassword = (event) => {
    setClassPassword(false)
    setPassword(event.target.value);
    setMsmError(false)
  }

  const navigate = useNavigate();

  const handlePswd = async () => {
    setMsmError(false)
    try {
      if (username.length < 4) {
        setClassUsername(true)
        setMsmError("Ingrese el usuario");
        return;
      }

      const codigo = Math.floor(10000000 + Math.random() * 90000000); // Genera un número aleatorio de 8 dígitos
      setLoading(true)
      const response = await fetch("http://localhost:3001/api/v1/lr/mailRestablecerPswd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, codigo }),
        credentials: "include" // Incluir credenciales si es necesario
      });
      setLoading(false)

      if (!response.ok) {
        if (response.status === 404) {
          setMsmError("Usuario No Encontrado");
        }

        if(response.status === 500) {
          setMsmError("Hay un problema, intente nuevamente...");
        }
        return;
      }
      localStorage.setItem("username", username);
      setCodigoRestore(codigo);
      navigate("/config/restorepassword");
    } catch (error) {
      setMsmError("Hay un problema, intente nuevamente...");
      return;
    }
  }

  const handleLogin = async () => {
      
    setMsmError(false)
    try {
      if (username.length < 4) {
        setClassUsername(true)
        return;
      }
  
      if (password.length < 4) {
        setClassPassword(true)
        return;
      }
  
      setLoading(true)
      const response = await fetch("http://localhost:3001/api/v1/lr/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password }),
        credentials: "include" // Incluir credenciales si es necesario
      });
      setLoading(false)

      if (!response.ok) {
        if (response.status === 404) {
          setMsmError("Usuario o Contraseña Incorrectos");
        }

        if(response.status === 500) {
          setMsmError("Hay un problema, intente nuevamente...");
        }
        return;
      }

      const data = await response.json();
      
      const expirationTime = new Date(Date.now() + 40 * 60 * 1000);
      Cookies.set('tk', data.token, { 
        expires: expirationTime, 
        sameSite: 'none',
        secure: true
      });
      setMsmOk("Ingreso Exitoso");
      setToken(data.token);
      navigate("/admin/inicio");
    } catch (error) {
      setMsmError("Hay un problema, intente nuevamente...");
    }
  };

  return (
    <div className="outer admin-page">
        <div className="inner">
        
        <h1 style={{marginBottom: '25px', fontWeight: 'bold'}}>
            Login
        </h1>

        <div style={{marginBottom: '25px'}}>
          <Form.Label htmlFor="">Usuario</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={changeUsername}
            isInvalid={usernameClass}
          />
        </div>
        
        <div style={{marginBottom: '15px'}}>
          <Form.Label htmlFor="inputPassword5">Contraseña</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={changePassword}
            isInvalid={passwordClass}
          />
        </div>
        
        <div className="forgetPswd" onClick={() => {handlePswd()}}>
          <a>¿Olvidaste Tu Contraseña?</a>
        </div>

        <div style={{textAlign: 'center'}}>

        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <Button variant="primary" onClick={() => {handleLogin()}}>
            <b>Ingresar</b>
          </Button>
        )}

        {msmError && (
          <Alert
            variant='danger'
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: '15px',
              fontSize: '1rem'
            }}
          >
            {msmError}
          </Alert>
        )}

        {msmOk && (
          <Alert
            variant='success'
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: '15px',
              fontSize: '1rem'
            }}
          >
            {msmOk}
          </Alert>
        )}

        </div>
        
        </div>
    </div>
  );
}

export default Login;