import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import TokenContext from '../context/TokenContext';

function Inicio() {
    return (
        <>
          <div>
            Inicio
          </div>
        </>
    );
}

export default Inicio;
