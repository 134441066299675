import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

const Sidebar = ({ routes }) => {
    const location = useLocation();
    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    return (
        <div className="sidebar">
            <div className="logo">
              <img 
                src={require("../../images/logolr.png")} 
                alt="..." 
                style={{
                    margin: 'auto',
                    width: '100%'
                }}
              />
            </div>
            <Nav className="flex-column side-content">
              {routes.map((prop, key) => {
                if (!prop.redirect)
                  return (
                    <li
                      className={
                        prop.upgrade
                          ? "activate"
                          : activeRoute(prop.layout + prop.path) ? "active" : ""
                      }
                      key={key}
                      activeClassName="active"
                    >
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                      >
                        <div className="text-side"> 
                            <i className={prop.icon} style={{marginRight: '10px'}}/>
                            {prop.name}
                        </div>
                      </NavLink>
                    </li>
                  );
                return null;
              })}
            </Nav>
        </div>
    );
}

export default Sidebar;