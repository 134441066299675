import React, { useState, useRef, useEffect, useContext, useNavigate } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import Cookies from 'js-cookie';

import SideBar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";

import dashboardRoutes from "../routes.js";

import TokenContext from "../context/TokenContext.js";

const Admin = () => {
  const { cookieNotExpired } = useContext(TokenContext);
  
    const validarCookie = () => {
      if (!cookieNotExpired()) {
        // Si la cookie no está activa o ha expirado, puedes redirigir a otra página o realizar alguna otra acción.
       console.log("cookie");
       /* navigate("/login"); */
      }
    }
    //validar que la cookie este activa
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
          if (prop.layout === "/admin") {
            return (
              <Route
                path={prop.path}
                element={<prop.component/>}
                key={key}
              />
            );
          } else {
            return null;
          }
        });
    };

    return (
        <>
          <div className="d-flex admin-page">

            <SideBar routes={dashboardRoutes}/>
            <div className="main-panel">
              <AdminNavbar />
              <div className="content">  
                <Routes>
                  { getRoutes(dashboardRoutes) }
                </Routes> 
              </div>
            </div>
          </div>
        </>
    );
}


export default Admin;