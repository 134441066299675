import React, { useState, useContext } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function CrearCuentas() {

    const [cedula, setCedula] = useState("");
    const [classCedula, setClassCedula] = useState(false);

    const [nombre, setNombre] = useState("");
    const [classNombre, setClassNombre] = useState(false);

    const [apellido, setApellido] = useState("");
    const [classApellido, setClassApellido] = useState(false);

    const [correo, setCorreo] = useState("");
    const [classCorreo, setClassCorreo] = useState(false);

    const [celular, setCelular] = useState("");
    const [classCelular, setClassCelular] = useState(false);

    const [tipoUsuario, setTipoUsuario] = useState("");
    const [classTipo, setClassTipo] = useState(false);

    const [loading, setLoading] = useState(false);
    const [AlertMsm, setAlertMsm] = useState(false);
    const [TypeAlert, setTypeAlert] = useState("success");

    const navigate = useNavigate();

    const changeCedula = (event) => {
        setClassCedula(false)
        const input = event.target.value;
    
        if (/^\d*$/.test(input)) {
          setCedula(input);
        }
    }

    const changeNombre = (event) => {
        setClassNombre(false)
        setNombre(event.target.value);
        setAlertMsm(false)
    }

    const changeApellido = (event) => {
        setClassApellido(false)
        setApellido(event.target.value);
        setAlertMsm(false)
    }

    const changeCorreo = (event) => {
        setClassCorreo(false)
        setCorreo(event.target.value);
        setAlertMsm(false)
    }

    const changeCelular = (event) => {
        setClassCelular(false)
        const input = event.target.value;
    
        if (/^\d*$/.test(input) && input.length <= 16) {
          setCelular(input);
          setAlertMsm(false)
        }
    }

    const handleChangeTipoUsuario = (event) => {
        setClassTipo(false)
        setTipoUsuario(event.target.value);
        setAlertMsm(false)
    };

    const limpiarInputs = () => {
        setTipoUsuario("");
        setCelular("");
        setCorreo("");
        setApellido("");
        setNombre("");
        setCedula("");
    }

    const token = Cookies.get('tk');

    const CreateUser = async () => {

        setAlertMsm(false);
        try {

            if (cedula.length < 4) {
                setClassCedula(true)
                setAlertMsm("Ingresa una Cedula Correcta ...");
                setTypeAlert("danger");
                return;
            }

            if (nombre.length < 4) {
                setClassNombre(true)
                setAlertMsm("Ingresa un Nombre Correcto ...");
                setTypeAlert("danger");
                return;
            }

            if (apellido.length < 4) {
                setClassApellido(true)
                setAlertMsm("Ingresa un Apellido Correcto ...");
                setTypeAlert("danger");
                return;
            }

            if (correo.length < 4) {
                setClassCorreo(true)
                setAlertMsm("Hay un problema, intente nuevamente...");
                setTypeAlert("danger");
                return;
            }

            if (celular.length < 4) {
                setClassCelular(true)
                setAlertMsm("Ingresa un Celular Correcto ...");
                setTypeAlert("danger");
                return;
            }

            if(tipoUsuario === 0) {
                setClassTipo(true)
                setAlertMsm("Selecciona un tipo de Usuario ...");
                setTypeAlert("danger");
                return;
            }

            setLoading(true)
            const response = await fetch("http://localhost:3001/api/v1/lr/crearUser", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(
                    {
                        cedula, 
                        nombre,
                        apellido,
                        correo,
                        celular,
                        tipoUsuario
                    }
                ),
                credentials: "include" // Incluir credenciales si es necesario
            });

            const data = await response.json();
            console.log("response", data)
            console.log("response code", data.code)

            if (data.code !== 100) {

                if (data.code === 110) {
                    console.log("no found");
                    setAlertMsm("Tu sesion Termino");
                    Cookies.remove('tk', { path: '/' });
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                    setTypeAlert("danger");
                }
    
                if (data.code === 130) {
                    console.log("no found");
                    setAlertMsm("Hay un problema, Intente Nuevamente");
                    setTypeAlert("danger");
                }

                if (data.code === 140) {
                    console.log("no found");
                    setAlertMsm("Esta cedula ya cuenta con usuario");
                    setTypeAlert("danger");
                }

                setLoading(false)
                return;
            }
            else
            {
                setAlertMsm("Usuario Creado Correctamente...");
                setTypeAlert("success");
                limpiarInputs();
            }

        } catch (error) {
            setAlertMsm("Hay un problema, intente nuevamente...");
            setTypeAlert("danger");
        }

        setLoading(false)
    }   

    return (
        <div>
            <div className="texto-titulo">
                <label>Completa los Datos de Usuario</label>
            </div>

            <Form className="form-usuario">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Cedula</Form.Label>
                  <Form.Control 
                    type="text"
                    value={cedula}
                    onChange={changeCedula}
                    isInvalid={classCedula}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nombres</Form.Label>
                  <Form.Control 
                    type="text"
                    value={nombre}
                    onChange={changeNombre}
                    isInvalid={classNombre}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control 
                    type="text"
                    value={apellido}
                    onChange={changeApellido}
                    isInvalid={classApellido}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Correo</Form.Label>
                  <Form.Control 
                    type="text"
                    value={correo}
                    onChange={changeCorreo}
                    isInvalid={classCorreo}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Celular</Form.Label>
                  <Form.Control 
                    type="text"
                    value={celular}
                    onChange={changeCelular}
                    isInvalid={classCelular}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Tipo de Usuario</Form.Label>
                  <Form.Select 
                    value={tipoUsuario} 
                    onChange={handleChangeTipoUsuario}
                    isInvalid={classTipo}
                  >
                    <option value={0}>Seleccione una Opcion</option>
                    <option value={1}>Studio</option>
                    <option value={2}>Modelo Independiente</option>
                  </Form.Select>
                </Form.Group>

                {loading ? (
                    <div style={{textAlign: 'center'}}>
                      <Spinner  animation="border" variant="primary" />
                    </div>
                ) : (
                    <Button 
                      variant="primary" 
                      style={{width: '100%', marginTop: '15px'}} 
                      onClick={() => {CreateUser()}}
                    >
                        <b>Crear Usuario</b>
                    </Button>
                )}

                {AlertMsm && (
                    <Alert
                      variant={TypeAlert}
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginTop: '15px',
                        fontSize: '1rem'
                      }}
                    >
                      {AlertMsm}
                    </Alert>
                )}
            </Form>

        </div>
    );
}

export default CrearCuentas;
