import React, { createContext, useState } from 'react';
const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [codigoRestore, setCodigoRestore] = useState("");
  const [usernameProvider, setUsernameProvider] = useState(null);
  // Función para verificar si la cookie no ha expirado
  const cookieNotExpired = () => {
    const cookieString = document.cookie;
    const cookieArray = cookieString.split("; ");
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i];
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName === "tk") { // Reemplaza "tuCookie" con el nombre de tu cookie
            const expiration = cookieValue;
            const expirationDate = new Date(expiration);
            const currentDate = new Date();
            return expirationDate > currentDate;
        }
    }
    return false; // La cookie no está presente
};


  return (
    <TokenContext.Provider value={{ token, setToken, codigoRestore, setCodigoRestore, usernameProvider, setUsernameProvider, cookieNotExpired }}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContext;