import Inicio from "./views/Inicio.js";
import Reporte from "./views/Reporte.js";
import CrearCuentas from "./views/CrearCuentas.js";

const dashboardRoutes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: "fa-regular fa-circle-play",
    component: Inicio,
    layout: "/admin"
  },
  {
    path: "/reporte",
    name: "Reporte",
    icon: "fa-regular fa-clipboard",
    component: Reporte,
    layout: "/admin"
  },
  {
    path: "/crear-cuenta",
    name: "Crear Cuenta",
    icon: "fa-solid fa-plus",
    component: CrearCuentas,
    layout: "/admin"
  }
];

export default dashboardRoutes;
